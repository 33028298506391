<template>
  <!-- 我的信息 -->
  <div class="aa">
    <el-dialog
      title="编辑学校信息"
      :visible.sync="dialogVisible"
      :before-close="handleClose"
      width="600px"
      v-if="title == '编辑学校信息'"
    >
      <el-form ref="form" :model="userobj" label-width="80px" size="mini">
        <el-form-item label="执教阶段">
          <el-select
            v-model="userobj.phaseName"
            @change="change1($event, '执教阶段')"
            placeholder="请选择执教阶段"
          >
            <el-option
              :label="item.phase"
              :value="item.phase"
              v-for="item in arre1"
              :key="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="学校区域">
          <el-cascader
            :disabled="!userobj.phaseName"
            v-model="address"
            :options="options"
            placeholder="请选择学校区域"
            @change="handleChange"
          ></el-cascader>
        </el-form-item>
        <el-form-item label="所在学校">
          <el-select
            v-model="userobj.schoolName"
            @change="change1($event, '所在学校')"
            :disabled="!address"
            placeholder="请选择所在学校"
          >
            <el-option
              :label="item.name"
              :value="item.name"
              v-for="item in arre2"
              :key="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="学校校区">
          <el-select
            v-model="userobj.campusName"
            @change="change1($event, '学校校区')"
            :disabled="!userobj.schoolName"
            placeholder="请选择所在学校"
          >
            <el-option
              :label="item.campus"
              :value="item.campus"
              v-for="item in arre4"
              :key="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="执教年级">
          <el-select
            v-model="userobj.gradeName"
            @change="change1($event, '执教年级')"
            :disabled="!userobj.campusName"
            placeholder="请选择执教年级"
          >
            <el-option
              :label="item.gradeName"
              :value="item.id"
              v-for="item in arre3"
              :key="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="执教班级">
          <el-select
            v-model="userobj.className"
            @change="change1($event, '执教班级')"
            :disabled="!userobj.campusName"
            placeholder="请选择执教班级"
          >
            <el-option
              :label="item.label"
              :value="item.label"
              v-for="item in arre5"
              :key="item.id"
            ></el-option>
          </el-select>
          <!-- <el-input v-model="userobj.className" placeholder="请输入执教班级"></el-input> -->
        </el-form-item>
        <el-form-item label="我的昵称">
          <el-input v-model="userobj.name" placeholder="请输入昵称"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" @click="saveclick">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      :title="str ? `${str}${title.slice(2)}` : title"
      :visible.sync="dialogVisible"
      :before-close="handleClose"
      :width="width"
      v-if="title != '编辑学校信息' && flag != 3"
    >
      <div class="table" v-if="!flag">
        <div class="th">
          <div class="td">校服信息</div>
          <div class="td">{{ title.slice(2) }}尺码</div>
          <div class="td">{{ title.slice(2) }}数量</div>
          <div class="td">操作</div>
        </div>
        <div class="optctions">
          <div
            class="optctionsItem"
            v-if="arre.length"
            v-for="item in arre"
            :key="item.id"
          >
            <img :src="item.clothingImg" alt="" />
            <div class="usertextbox">
              <div class="t1">
                {{ item.classifyName }}·{{ item.typeName }}·{{ item.positionName }}
              </div>
              <div class="t2">学生姓名:{{ item.studentName }}</div>
              <div class="t2">备注：{{ item.remark }}</div>
            </div>
            <div class="size" v-if="title.slice(2) == '调换'">
              <div class="size1">原{{ item.oldSize }}</div>
              <img src="/assets/2-3.1@2x.png" alt="" />
              <div class="size1">新{{ item.newSize }}</div>
            </div>
            <div class="size" v-if="title.slice(2) != '调换'">
              <div class="size1">{{ item.newSize }}</div>
            </div>
            <div class="num">x{{ item.quantity }}</div>
            <img class="redact" src="/assets/2-3.2@2x.png" alt="" @click="redact(item)" />
          </div>

          <div class="waringmsg" v-if="!arre.length">
            <img src="/assets/2-1.1@2x.png" alt="" />
            <div class="msgtext">
              点击<span
                >"新增{{ title.slice(2) == "新增" ? "校服" : title.slice(2) }}"</span
              >按钮，进行校服调换
            </div>
          </div>
          <div class="btnss">
            <div class="btn" @click="add">
              新增{{ title.slice(2) == "新增" ? "校服" : title.slice(2) }}
            </div>
            <div class="btn" @click="confirmAdd">确认{{ title.slice(2) }}</div>
          </div>
        </div>
      </div>

      <!-- 新增校服 -->
      <el-form
        ref="form"
        :model="forms"
        :rules="rules"
        label-width="100px"
        size="mini"
        v-if="flag == 1"
      >
        <el-form-item label="校服分类:" prop="classifyName">
          <el-select
            v-model="forms.classifyName"
            @change="change($event, '校服分类')"
            placeholder="请选择"
          >
            <el-option
              :label="item.classifyName"
              :value="item.id"
              v-for="item in arr"
              :key="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="校服类型:" prop="typeName">
          <el-select
            v-model="forms.typeName"
            :disabled="!forms.classifyName"
            @change="change($event, '校服类型')"
            placeholder="请选择"
          >
            <el-option
              :label="item.typeName"
              :value="item.typeName"
              v-for="(item, index) in arr1"
              :key="index"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="校服部位:" prop="positionName">
          <el-select
            v-model="forms.positionName"
            :disabled="!forms.typeName"
            @change="change($event, '校服部位')"
            placeholder="请选择"
          >
            <el-option
              :label="item.positionName"
              :value="item.positionName"
              v-for="(item, index) in arr2"
              :key="index"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="调换前尺码:" prop="oldSize" v-if="title == '校服调换'">
          <el-select
            v-model="forms.oldSize"
            :disabled="!forms.positionName"
            @change="change"
            placeholder="请选择"
          >
            <el-option
              :label="item.clothingSize"
              :value="item.clothingSize"
              v-for="(item, index) in arr3"
              :key="index"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          :label="title == '校服调换' ? '调换后尺码:' : title.slice(2) + '尺码'"
          prop="newSize"
        >
          <el-select
            v-model="forms.newSize"
            :disabled="!forms.positionName"
            @change="change"
            placeholder="请选择"
          >
            <el-option
              :label="item.clothingSize"
              :value="item.clothingSize"
              v-for="(item, index) in arr3"
              :key="index"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="`${title.slice(2)}数量:`" prop="quantity">
          <el-input
            v-model="forms.quantity"
            placeholder="请输入需要调换的数量"
          ></el-input>
        </el-form-item>
        <el-form-item label="学生姓名:">
          <el-input v-model="forms.studentName" placeholder="请填写(非必填)"></el-input>
        </el-form-item>
        <el-form-item label="备注:">
          <el-input v-model="forms.remark" placeholder="请填写(非必填)"></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer" v-if="flag == 1">
        <el-button @click="remove(forms.id)" v-if="str"
          >删除{{ title.slice(2) }}</el-button
        >
        <el-button type="primary" @click="save">保存</el-button>
      </span>
    </el-dialog>

    <el-dialog
      :visible.sync="dialogVisible"
      :before-close="handleClose"
      width="500px"
      v-if="flag == 3"
      class="msgkuang"
      center
    >
      <!-- "新增{{ title.slice(2)=='新增'?'校服':title.slice(2)}}"< -->
      <div class="lefttit">
        {{ title }}
      </div>
      <div class="textcenter">是否确认{{ title.slice(2) }}校服？</div>
      <div class="btnss1">
        <div class="btnone" @click="handleClose">取消</div>
        <div class="btntwo" @click="xinzen">确认{{ title.slice(2) }}</div>
      </div>
      <div style="height: 40px"></div>
    </el-dialog>
  </div>
</template>

<script>
import {
  uniformSchool,
  pageType,
  pagePosition,
  pageSize,
  createorder,
  orderInfo,
  updateorder,
  creat,
  removeorider,
  schoolimg,
} from "@/api/xiaofu";
import {
  userget,
  pagePhase,
  pageSchool,
  pageCampus,
  dictData,
  update,
} from "@/api/my.js";
import city from "@/common/area";
export default {
  props: ["dialogVisible", "title", "active"],
  data() {
    return {
      address: [],
      options: [],
      arre1: [],
      arre2: [],
      arre3: [],
      arre4: [],
      arre5:[{label:'1班',value:0},{label:'2班',value:1},{label:'3班',value:3},{label:'4班',value:4},{label:'5班',value:5},{label:'6班',value:6},{label:'7班',value:7},{label:'8班',value:8},{label:'9班',value:9},{label:'10班',value:10},{label:'11班',value:11},{label:'12班',value:12},{label:'13班',value:13},{label:'14班',value:14},{label:'15班',value:15}],
      str: "",
      userobj: {
        phaseName: "",
        className: "",
        name: "",
      },
      width: "1000px", //1000px 600px
      arre: [],
      arr: [],
      arr1: [],
      arr2: [],
      arr3: [],
      flag: 0,
      sizeForm: {
        phaseName: "",
        provinceName: "",
        cityName: "",
        areaName: "",
        schoolName: "",
        campusName: "",
        gradeName: "",
      },
      query: {
        pageNo: 1,
        pageSize: 10,
        useType: 0,
        type: 0,
      },
      forms: {
        classifyName: "",
        typeName: "",
        positionName: "",
        oldSize: "", //原尺码
        newSize: "", //新增
        quantity: "", //数量
        studentName: "", //学生名字
        type: 0, //是草稿
        remark: "", //备注
        clothingImg: "",
        useType: 0,
      },
      rules: {
        classifyName: [{ required: true, message: "请选择校服分类", trigger: "change" }],
        typeName: [{ required: true, message: "请选择校服类型", trigger: "change" }],
        positionName: [{ required: true, message: "请选择校服部位", trigger: "change" }],
        oldSize: [{ required: true, message: "请选择尺码", trigger: "change" }],
        newSize: [{ required: true, message: "请选择新尺码", trigger: "change" }],
        quantity: [{ required: true, message: "请输入数量", trigger: "change" }],
      },
    };
  },
  watch: {
    forms: {
      handler() {
        console.log(123);
        this.initoption();
      },
      deep: true, // 深度监听
    },
    userobj: {
      handler() {
        this.selectoptions();
      },
      deep: true, // 深度监听
    },
    title(xin) {
      console.log("(!!!!!!!!!!!!!!!!);", xin);
      if (xin == "校服调换") {
        this.forms.useType = 0;
        this.query.useType = 0;
      } else if (xin == "校服新增") {
        this.forms.useType = 1;
        this.query.useType = 1;
      } else if (xin == "校服退订") {
        this.forms.useType = 2;
        this.query.useType = 2;
      } else if (xin == "校服补发") {
        this.forms.useType = 3;
        this.query.useType = 3;
      }
      this.initpage();
    },
  },
  created() {
    console.log(city, "citycitycitycity");
    this.options = city;
    if (this.title == "校服调换") {
      this.forms.useType = 0;
      this.query.useType = 0;
    } else if (this.title == "校服新增") {
      this.forms.useType = 1;
      this.query.useType = 1;
    } else if (this.title == "校服退订") {
      this.forms.useType = 2;
      this.query.useType = 2;
    } else if (this.title == "校服补发") {
      this.forms.useType = 3;
      this.query.useType = 3;
    }
    this.selectoptions();
    this.initpage();
    this.initoption();
    this.getmyinfo();
  },
  methods: {
    close() {
      this.dialogVisible = false;
      this.$router.go(0);
    },
    async saveclick() {
      console.log(this.userobj, "this.userobj,");

      for (var i in this.userobj) {
        if (i != "nickname" && i != "avatar") {
          if (!this.userobj[i]) {
            this.$message({
              message: "请补全信息",
              type: "warning",
            });
            return;
          }
        }
      }
      const res = await update(this.userobj);
      if (res.code == 0) {
        this.dialogVisible = false;
        this.$message({
          message: "修改成功",
          type: "success",
        });
        this.$emit("getuser");
      }
    },
    handleChange(value) {
      console.log(value, 660, this.userobj);
      if (value.length) {
        this.userobj.provinceCode = value[0];
        this.userobj.cityCode = value[1];
        this.userobj.areaCode = value[2];
      }
      var itesm = city.filter((item) => item.value == value[0])[0];
      var itesm1 = itesm.children.filter((item) => item.value == value[1])[0];
      this.userobj.provinceName = city.filter((item) => item.value == value[0])[0][
        "label"
      ];
      this.userobj.cityName = itesm.children.filter((item) => item.value == value[1])[0][
        "label"
      ];
      this.userobj.areaName = itesm1.children.filter((item) => item.value == value[2])[0][
        "label"
      ];
      console.log(this.userobj, "this.userobj.areaName");
    },
    async selectoptions() {
      // 执行教育阶段
      const res = await pagePhase({
        pageNo: 1,
        pageSize: 100,
      });
      if (res.code == 0) {
        this.arre1 = res.data.list;
        var userobj1 = this.userobj;

        // 所在学校
        const res1 = await pageSchool({
          cityCode: userobj1.cityCode,
          areaCode: userobj1.areaCode,
          phaseId: userobj1.phaseId,
          provinceCode: userobj1.provinceCode,
          pageNo: 1,
          pageSize: 100,
        });
        console.log(res1, "res1res1res1res1res1res1res1res1res1res1res1res1res1690");

        if (res1.code == 0) {
          this.arre2 = res1.data.list;

          if (!this.arre2.length) {
            this.userobj.schoolName = "";
          }
          var userobj1 = this.userobj;
          // 学校校区
          const res3 = await pageCampus({
            cityCode: userobj1.cityCode,
            areaCode: userobj1.areaCode,
            phaseId: userobj1.phaseId,
            provinceCode: userobj1.provinceCode,
            schoolId: userobj1.schoolId,
            pageNo: 1,
            pageSize: 100,
          });
          if (res3.code == 0) {
            this.arre4 = res3.data.list;
            if (!this.arre4.length) {
              this.userobj.campusName = "";
              this.userobj.gradeName = "";
            }
          }

          const res2 = await dictData({
            page: 1,
           	pageSize: -1,
           phaseId:this.userobj.phaseId
        
          });
          console.log(res2, "res2655");

          if (res2.code == 0) {
            console.log(res2.data, 632632);
            this.arre3 = res2.data.list;
            if (!this.arre3.length) {
              this.userobj.gradeName = "";
            }
          }
        }
      }
    },
    async getmyinfo() {
      console.log(123123);
      const res = await userget();
      if (res.code == 0) {
        this.userobj = res.data;
        console.log(this.userobj, "this.userobj");
        this.address = [
          res.data.provinceCode + "",
          res.data.cityCode + "",
          res.data.areaCode + "",
        ];
      }
    },
    async xinzen() {
      const res = await creat({
        type: this.forms.useType,
        schoolName: this.userobj.schoolName,
        userName: this.userobj.nickname,
        orderInfoSaveReqVOS: this.arre,
        campusName: this.userobj.campusName,
      });
      if (res.code == 0) {
        this.$emit("getlist");
        var str = this.title.slice(2) + "成功";
        this.$message.success(str);
        this.dialogVisible = false;
      }
    },
    redact(item) {
      this.str = "编辑";

      this.flag = 1;
      this.width = "600px";
      this.forms = {
        ...item,
        type: 0, //是草稿
        useType: "",
      };
    },
    confirmAdd() {
      this.flag = 3;
    },
    async initpage() {
      const res = await orderInfo(this.query);
      if (res.code == 0) {
        this.arre = res.data.list;
      }
    },
    async remove(id) {
      const res = await removeorider(id);
      if (res.code == 0) {
        this.$message({
          message: "删除成功",
          type: "success",
        });
        this.flag = 0;
        this.width = "1000px";
        this.initpage();
      } else {
        this.$message({
          message: res.msg,
          type: "warning",
        });
      }
    },
    save() {
      // const res =await
      if (this.forms.newSize == this.forms.oldSize) {
        this.$message({
          message: "新旧尺码不能一样",
          type: "warning",
        });
        return;
      }
      var that = this;
      this.$refs["form"].validate((valid) => {
        if (valid) {
          that.clicksave();
        } else {
          return false;
        }
      });
    },
    async clicksave() {
      var xin = this.title;
      if (xin == "校服调换") {
        this.forms.useType = 0;
        this.query.useType = 0;
      } else if (xin == "校服新增") {
        this.forms.useType = 1;
        this.query.useType = 1;
      } else if (xin == "校服退订") {
        this.forms.useType = 2;
        this.query.useType = 2;
      } else if (xin == "校服补发") {
        this.forms.useType = 3;
        this.query.useType = 3;
      }
      if (this.forms.id) {
        const ress = await updateorder(this.forms);
        if (ress.code == 0) {
          this.flag = 0;
          this.width = "1000px";
        }
      } else {
        const res = await createorder(this.forms);
        if (res.code == 0) {
          this.flag = 0;
          this.width = "1000px";
        }
      }
      this.initpage();
    },
    change1(e, str) {
      if (str == "执教阶段") {
        var ind = this.arre1.findIndex((item) => item.phase == e);
        console.log(ind, 865);
        this.userobj.phaseId = this.arre1[ind]["id"];
      } else if (str == "所在学校") {
        var ind = this.arre2.findIndex((item) => item.name == e);
        this.userobj.schoolId = this.arre2[ind]["id"];
      } else if (str == "学校校区") {
        var ind = this.arre4.findIndex((item) => item.campus == e);
        this.userobj.campusId = this.arre4[ind]["id"];
      } else if (str == "执教年级") {
        console.log(this.arre3, e, 873);
        var ind = this.arre3.findIndex((item) => item.id == e);
        console.log(this.arre3[ind], "this.arre3[ind]877");
        this.userobj.grade = this.arre3[ind]["gradeName"];
      }
    },
    async change(e, str) {
      if (str == "校服分类") {
        var index = this.arr.findIndex((item) => item.id == e);
        this.forms.classifyName = this.arr[index]["classifyName"];
        this.forms.classifyId = this.arr[index]["id"];
      } else if (str == "校服类型") {
        console.log(e, "eeeee", this.arr1);
        var index = this.arr1.findIndex((item) => item.typeName == e);
        this.forms.typeName = this.arr1[index]["typeName"];
        this.forms.typeId = this.arr1[index]["id"];
      } else if (str == "校服部位") {
        console.log(this.arr2, "this.arr2this.arr2");
        var index = this.arr2.findIndex((item) => item.positionName == e);
        this.forms.positionName = this.arr2[index]["positionName"];
        this.forms.positionId = this.arr2[index]["id"];
        console.log(this.forms, "876this.arr2this.arr2");
      } else {
        console.log(901901);
        const res = await schoolimg({
          pageNo: 1,
          pageSize: 100,
          classifyId: this.forms.classifyId,
          typeId: this.forms.typeId,
          positionId: this.forms.positionId,
        });
        if (res.code == 0) {
          this.forms.clothingImg = res.data.list[0]["clothingImg"];
        }
        //   this.forms.oldSize = value[0]['label']
        // }else if(str=='调换后尺码'){
        //   this. forms.newSize = value[0]['label']
        // }else if(str =='新增尺码'){
        //   this.forms.newSize = value[0]['label']
      }
    },
    async initoption() {
      this.getmyinfo();
      var ids1 = "";
      var ids2 = "";
      console.log(this.userobj, 937);

      const resn = await schoolimg({
        pageNo: 1,
        pageSize: 100,
        schoolId: this.userobj.schoolId,
      });
      if (resn.code == 0) {
        ids1 = resn.data.list.map((item) => item.classifyId);
        ids2 = resn.data.list.map((item) => item.typeId);
        const res = await uniformSchool({
          pageNo: 1,
          pageSize: 100,
          idStr: ids1.join("-"),
        });
        if (res.code == 0) {
          this.arr = res.data.list;
          console.log(this.arr, "869this.arr");
        }
      }

      const ress = await pageType({
        pageNo: 1,
        pageSize: 100,
        idStr: ids2.join("-"),
      });
      if (ress.code == 0) {
        this.arr1 = ress.data.list;
      }
      const res1 = await pagePosition({
        pageNo: 1,
        pageSize: 100,
        classifyId: this.forms.classifyId,
        typeId: this.forms.typeId,
      });
      if (ress.code == 0) {
        this.arr2 = res1.data.list;
        console.log(this.arr2, "913", this.forms.positionName);

        if (!this.arr2.length) {
          this.forms.positionName = "";
        } else {
          var ind = this.arr2.findIndex(
            (item) => item.positionName == this.forms.positionName
          );
          if (ind == -1) {
            this.forms.positionName = "";
          }
        }
      }
      const res2 = await pageSize({
        pageNo: 1,
        pageSize: 100,
        classifyId: this.forms.classifyId,
        typeId: this.forms.typeId,
        positionId: this.forms.positionId,
      });
      if (ress.code == 0) {
        this.arr3 = res2.data.list;
        if (!this.arr3.length) {
          this.forms.oldSize = "";
          this.forms.newSize = "";
        }
      }
    },
    handleClose() {
      console.log("关闭");
      this.flag = 0;
      this.width = "1000px";
      this.$emit("handleClose");
    },
    add() {
      this.flag = 1;
      this.width = "600px";
      this.forms = {
        classifyName: "",
        typeName: "",
        positionName: "",
        oldSize: "", //原尺码
        newSize: "", //新增
        quantity: "", //数量
        studentName: "", //学生名字
        type: 0, //是草稿
        remark: "", //备注
        clothingImg: "",
        useType: "",
      };
    },
  },
};
</script>

<style scoped>
>>> .el-dialog {
  width: 600px;
  background: #ffffff;
  border-radius: 10px !important;
}
>>> el-input {
  width: 402px;
}
>>> .el-select {
  display: block;
}
>>> .el-dialog__header {
  text-align: left;
  border-bottom: 1px solid #eeeeee;
}
.table {
  width: 960px;
  overflow: hidden;
  margin-top: 20px;
}
.th {
  width: 960px;
  height: 40px;
  background: #f5f5f5;
  border-radius: 6px 6px 0px 0px;
  border: 1px solid #eeeeee;
  display: flex;
  align-items: center;
  font-family: Microsoft YaHei;
  font-weight: 400;
  font-size: 14px;
  color: #666666;
}
.th .td:first-child {
  margin-left: 20px;
}
.th .td:nth-child(2) {
  margin-left: 542px;
}
.th .td:nth-child(3) {
  margin-left: 109px;
}
.th .td:nth-child(4) {
  margin-left: 71px;
}
.optctions {
  width: 100%;
  overflow: hidden;
}
.waringmsg {
  margin: auto;
  margin-top: 100px;
  margin-bottom: 175px;
}
.waringmsg img {
  width: 180px;
  height: 105px;
}
.msgtext {
  text-align: center;
  font-family: Microsoft YaHei;
  font-weight: 400;
  font-size: 14px;
  color: #aaaaaa;
}
.msgtext span {
  color: #ff7f2f;
}
.btnss {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 70px;
}
.btnss .btn:first-child {
  width: 88px;
  height: 36px;
  background: #ff7f2f;
  border-radius: 6px;
  line-height: 36px;
  text-align: center;
  margin-left: 750px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  cursor: pointer;
}
.btnss .btn:last-child {
  width: 88px;
  height: 36px;
  background: #2f7aff;
  border-radius: 6px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  text-align: center;
  line-height: 36px;
  cursor: pointer;
}
>>> .el-form-item__content {
  width: 382px;
}
.optctionsItem {
  width: 96%;
  display: flex;
  overflow: hidden;
  justify-content: space-between;
  padding: 10px;
  align-items: center;
  border-bottom: 1px solid #eeeeee;
}
.optctionsItem img {
  width: 90px;
  height: 90px;
  background: #fbfbfb;
  border-radius: 10px;
}
.usertextbox {
  width: 398px;
  text-align: left;
}

.usertextbox .t1 {
  font-family: Microsoft YaHei;
  font-weight: 400;
  font-size: 16px;
  color: #333333;
  margin-bottom: 18px;
}
.usertextbox .t2 {
  font-family: Microsoft YaHei;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 10px;
  color: #999999;
}
.size {
  display: flex;
  align-items: center;
}
.size1 {
  font-family: Microsoft YaHei;
  font-weight: 400;
  font-size: 14px;
  color: #2f7aff;
}
.size img {
  width: 15px;
  height: 12px;
}
.num {
  font-family: Microsoft YaHei;
  font-weight: 400;
  font-size: 14px;
  color: #2f7aff;
}
.redact {
  width: 18px !important;
  height: 18px !important;
  cursor: pointer;
}

.msgkuang >>> .el-dialog__header {
  border: none !important;
}
.lefttit {
  font-family: Microsoft YaHei;
  font-weight: bold;
  font-size: 18px;
  color: #333333;
  margin-left: 30px;
}
.msgkuang >>> .el-dialog__body {
  padding: 0 !important;
}
.textcenter {
  font-family: Microsoft YaHei;
  font-weight: 400;
  font-size: 16px;
  color: #666666;
  margin-left: 30px;
  margin-top: 26px;
}
.btnss1 {
  width: 212px;
  margin-left: 274px;
  margin-top: 65px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
}
.btnone {
  width: 88px;
  height: 36px;
  background: #ffffff;
  border-radius: 6px;
  border: 1px solid #e6e6e6;
  text-align: center;
  line-height: 36px;
  cursor: pointer;
}
.btntwo {
  width: 88px;
  height: 36px;
  background: #2f7aff;
  border-radius: 6px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  text-align: center;
  line-height: 36px;
  cursor: pointer;
}
>>> .el-cascader {
  width: 100%;
}
</style>
